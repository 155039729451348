/* eslint-disable */

<template>
  <div>
    <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
      <div class="vx-col w-full">
        <vx-card class="mb-base">
          <h1>Properties</h1>
          <vs-divider />
          <h2>UiPath.Core.Activities.ExtractData</h2>
          <!--- <div class="grid grid-cols-2 gap-4 mt-6"> --->
          <div class="grid grid-cols-1 gap-4 mt-6">
            <div v-for="section in properties" :key="section.id" class="p-5 border-solid p-2 rounded" style="border-color: #00b1ff">
              <h3 class="font-bold break-all">{{ section.name }}</h3>
              <vs-divider />
              <div v-for="field in section.fields" :key="field.name" class="vx-row mt-4">
                <div class="vx-col flex w-1/3">
                  <h3 class="break-all self-center">{{ field.name }}</h3>
                </div>
                <div class="vx-col w-2/3">
                  <v-select
                    v-if="field.type === 'dropdown'"
                    :options="field.options"
                    v-model="field.value"
                    :disabled="field.disabled"
                    class="w-full self-center"
                    placeholder="Select Option From Dropdown..."
                  />
                  <vs-input
                    v-if="field.type === 'text'"
                    v-model="field.value"
                    :readonly="field.disabled"
                    class="inputx w-full self-center"
                  />
                  <div class="flex items-center" v-if="field.type === 'switch'">
                    <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
                    <h4 class="ml-2">{{ field.desc }}</h4>
                  </div>
                  <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
                  <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      properties: [
        {
          id: 0,
          name: '*Common',
          fields: [
            {
              name: 'ContinueOnError',
              type: 'dropdown',
              value: 'True',
              answer: 'True',
              disabled: true,
              options: ['True', 'False'],
              score: null,
            },
            {
              name: 'Display Name',
              type: 'text',
              value: 'Extract Structured Data HTML',
              answer: 'Extract Structured Data HTML',
              disabled: true,
              score: null,
            },
          ],
        },
        {
          id: 1,
          name: '*Input',
          fields: [
            {
              name: 'ExtractMetaData',
              type: 'text',
              value: '"<extract> <row exact=\'1\' > <',
              answer: '"<extract> <row exact=\'1\' > <',
              disabled: true,
              score: null,
            },
          ],
        },
        {
          id: 2,
          name: '*Target',
          fields: [
            {
              name: 'ClippingRegion',
              type: 'text',
              value: '',
              answer: '',
              disabled: true,
              score: null,
            },
            {
              name: 'Element',
              type: 'text',
              value: 'Enter a VB expression',
              answer: 'Enter a VB expression',
              disabled: true,
              score: null,
            },
            {
              name: 'Selector',
              type: 'text',
              value: '"<webctrl idx=\'1\' tag= \'HTML\' ',
              answer: '"<webctrl idx=\'1\' tag= \'HTML\' ',
              disabled: true,
              score: null,
            },
            {
              name: 'Timeout (milliseconds)',
              type: 'text',
              value: 'Enter a VB expression',
              answer: 'Enter a VB expression',
              disabled: true,
              score: null,
            },
            {
              name: 'WaitForReady',
              type: 'dropdown',
              value: '',
              answer: 'COMPLETE',
              disabled: false,
              options: ['NONE', 'INTERACTIVE', 'COMPLETE'],
              score: null,
            },
          ],
        },
        {
          id: 3,
          name: '*Misc',
          fields: [
            {
              name: 'Private',
              type: 'switch',
              value: false,
              answer: true,
              disabled: false,
              score: null,
            },
          ],
        },
        {
          id: 4,
          name: '*Options',
          fields: [
            {
              name: 'DelayBetweenPageMS',
              type: 'text',
              value: 'The amount of time, in milliseconds',
              answer: 'The amount of time, in milliseconds',
              disabled: true,
              score: null,
            },
            {
              name: 'MaxNumberOfResults',
              type: 'text',
              value: '50',
              answer: '50',
              disabled: true,
              score: null,
            },
            {
              name: 'NextLinkSelector',
              type: 'text',
              value: '"<webctrl parentid=\'next-bo',
              answer: '"<webctrl parentid=\'next-bo',
              disabled: true,
              score: null,
            },
            {
              name: 'SendWindowMessages',
              desc: 'If selected, the click on the switch',
              type: 'switch',
              value: false,
              answer: false,
              disabled: true,
              score: null,
            },
            {
              name: 'SimulateClick',
              desc: 'If selected, the click on the switch',
              type: 'switch',
              value: false,
              answer: true,
              disabled: false,
              score: null,
            },
          ],
        },
        {
          id: 5,
          name: '*Output',
          fields: [
            {
              name: 'DataTable',
              type: 'text',
              value: 'ExtractDataTable',
              answer: 'ExtractDataTable',
              disabled: true,
              score: null,
            },
          ],
        },
      ],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.properties.forEach((category, category_i) => {
        if (category.fields) {
          category.fields.forEach((field, field_i) => {
            if (field.value === field.answer) {
              this.properties[category_i].fields[field_i].score = true;
              totalScore++;
            } else {
              this.properties[category_i].fields[field_i].score = false;
            }
          });
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  created() {},
  components: {
    Prism,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.Drag239834441344614400 {
  font-size: 23px;
}
</style>
