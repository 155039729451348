import { render, staticRenderFns } from "./Lgx240113882130022400.vue?vue&type=template&id=de42285c&scoped=true&"
import script from "./Lgx240113882130022400.vue?vue&type=script&lang=js&"
export * from "./Lgx240113882130022400.vue?vue&type=script&lang=js&"
import style0 from "./Lgx240113882130022400.vue?vue&type=style&index=0&id=de42285c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de42285c",
  null
  
)

export default component.exports